import {
    Card,
    CfwPayloadType,
    FareType,
    MetaDataType,
    SearchData,
    SearchIntentType,
    ServicesData,
    SrpFareObject,
    SubTravelOptions
} from '@/types/srp'
import { isEmpty } from '../general/browserHelper'
import { formatCurrency, replacePlaceholders } from '..'
import { getFormattedDate, getFormattedDateCfw } from '../general/date'
import { getUserAuthValues, isUserSignedIn } from '../general/user'
import { listOfJourneyKeys, LOGGED_IN, NON_LOGGED_IN } from '@/constants/Srp'
import Statsig from 'statsig-js'

export const getPaxDetails = (paxCriteria: any[]) => {
    let [adults, children, infants] = [0, 0, 0]
    if (isEmpty(paxCriteria) || !Array.isArray(paxCriteria)) {
        return [adults, children, infants]
    }
    paxCriteria.forEach((pax: any) => {
        if (pax?.type === 'ADT') adults = pax.count
        if (pax?.type === 'CHD') children = pax.count
        if (pax?.type === 'INF') infants = pax.count
    })
    return [adults, children, infants]
}

export const isPerAdultPricing = (paxCriteria: any[]) => {
    let [adults, children, infants] = getPaxDetails(paxCriteria)
    if (adults + children + infants > 1) {
        return true
    }
    return false
}

export const checkMultiAirlineCase = (flights: any[]) => {
    const multiAirlinePairsExclude = [
        ['I5', 'IX'],
        ['IX', 'I5']
    ]
    let isMultiAirline = false
    let airlineCode = flights?.[0]?.airlineCode || ''
    flights.forEach(flight => {
        if (
            airlineCode !== flight.airlineCode &&
            !multiAirlinePairsExclude.some(pair => pair[0] === airlineCode && pair[1] === flight.airlineCode)
        ) {
            isMultiAirline = true
        }
    })
    return isMultiAirline
}

export const getFlightInfo = (card: Card, metaData: MetaDataType) => {
    const { summary, travelOptionId } = card || {}
    const { firstDeparture, lastArrival, stops, timelineText, totalDuration, flights = [] } = summary || {}
    const firstFlight = flights?.[0] || {}
    const departureTime = firstDeparture?.airport?.time
    const arrivalTime = lastArrival?.airport?.time
    const isMultiAirline = checkMultiAirlineCase(summary.flights)
    const flightNumber = `${firstFlight.airlineCode}-${firstFlight.flightNumber}`
    const { airlineCode } = firstDeparture || {}
    const airlineName = metaData?.airlineDetail?.[airlineCode]?.name || ''
    const from = metaData?.airportDetail?.[firstDeparture?.airport?.code]?.city || ''
    const to = metaData?.airportDetail?.[lastArrival?.airport?.code]?.city || ''
    return {
        flightNumber,
        airlineCode,
        airlineName,
        isMultiAirline,
        departureTime,
        arrivalTime,
        stops,
        timelineText,
        totalDuration,
        travelOptionId,
        from,
        to
    }
}

export const getFaresInfo = (
    card: Card,
    subTravelOptions: SubTravelOptions,
    fares: SrpFareObject,
    sortcards = false
) => {
    let finalFareObjectList: any = []
    const { subTravelOptionIds = [], travelOptionId = '' } = card || {}
    subTravelOptionIds.forEach(subTravelOptionId => {
        const {
            fareList = [],
            cheapestFareId = '',
            subTravelOptionKey = '',
            sortList = []
        } = subTravelOptions[subTravelOptionId] || {}
        let fareObjectList: any = fareList.map(fare => {
            const { fareId = '', benefits, benefitTags = {}, displayText = {} } = fare || {}
            const isCheapest = cheapestFareId === fareId
            return {
                ...(fares?.[fareId] || {}),
                displayText,
                benefits,
                benefitTags,
                subTravelOptionId,
                travelOptionId,
                isCheapest,
                subTravelOptionKey
            }
        })
        if (sortcards && Array.isArray(sortList) && !isEmpty(sortList)) {
            fareObjectList = sortList
                .map(fareId => {
                    return fareObjectList.find((fareObject: any) => fareObject.fareId === fareId)
                })
                .filter(Boolean)
        }
        finalFareObjectList = [...finalFareObjectList, ...fareObjectList]
    })
    return finalFareObjectList
}

export const getCouponCallout = (couponDetails, showPerAdultPricing) => {
    if (isEmpty(couponDetails)) return { couponCallout: '', couponCode: '' }
    const { adultCouponMessage, couponCode, discountAmount, discountedPrice, message } = couponDetails || {}
    let couponMessage = (showPerAdultPricing ? adultCouponMessage : message) || ''
    if (couponMessage) {
        couponMessage = couponMessage
            .replace('{couponCode}', couponCode)
            .replace('{discountAmount}', formatCurrency(discountAmount))
        return { couponCallout: couponMessage, couponCode }
    }
    return { couponCallout: '', couponCode }
}

export const getPricingDetails = ({ strikeOffDetails, totalPrice }) => {
    let displayPrice = totalPrice
    let strikeOffPrice = strikeOffDetails?.strikeOffPrice
    if (!isEmpty(strikeOffPrice)) {
        displayPrice = strikeOffDetails.displayPrice
    }
    return { displayPrice, strikeOffPrice }
}

export const getFarePricingAttributes = ({ fare, showPerAdultPricing }) => {
    if (isEmpty(fare)) return {}
    let { pricing } = fare || {}
    const pricingObject = showPerAdultPricing ? pricing.perAdultPricing : pricing.totalPricing
    const { couponDetails, strikeOffDetails, totalBaseFare, totalTax, totalPrice } = pricingObject
    const { couponCallout, couponCode } = getCouponCallout(couponDetails, showPerAdultPricing)
    const { displayPrice, strikeOffPrice } = getPricingDetails({
        strikeOffDetails,
        totalPrice
    })
    return {
        strikeOffPrice,
        displayPrice,
        couponCallout,
        couponCode,
        totalBaseFare,
        totalTax,
        totalPrice
    }
}

export const getCheapestFareInCard = (cardsList: Card[], subTravelOptions: any, fares: any) => {
    if (Array.isArray(cardsList)) {
        return cardsList.map(card => {
            const fareList = getFaresInfo(card, subTravelOptions, fares)
            return fareList.find(fare => {
                const { isCheapest } = fare || {}
                return isCheapest
            })
        })
    }
    return []
}

export const getSearchIntentDetails = (searchIntent: SearchIntentType) => {
    const { J1, J2 } = searchIntent || {}
    const J1from = J1?.origin
    const J1to = J1?.destination
    const { paxCriteria, departDate = '' } = J1 || {}
    const J1travelDate = getFormattedDateCfw(departDate).toUpperCase()

    const J2from = J2?.origin
    const J2to = J2?.destination
    const J2departDate = J2?.destination || ''
    const J2travelDate = getFormattedDateCfw(J2departDate).toUpperCase()

    return { J1from, J1to, J1travelDate, J2from, J2to, J2travelDate, paxCriteria }
}

export const getSpecialRTFareCase = (onwardFare: any, returnFare: any, travelOptionIdsToFareIdsMap: any) => {
    const {
        brand: onwardBrand,
        fareCategory: onwardFareCategory,
        fareGroup: onwardFareGroup,
        travelOptionId: onwardTravelOptionId
    } = onwardFare || {}
    const {
        brand: returnBrand,
        fareCategory: returnFareCategory,
        fareGroup: returnFareGroup,
        travelOptionId: returnTravelOptionId
    } = returnFare || {}
    let isSpecialRT = false
    if (
        onwardBrand &&
        returnBrand &&
        onwardFareCategory &&
        onwardFareGroup &&
        onwardFareCategory === returnFareCategory &&
        onwardFareGroup === returnFareGroup
    ) {
        const key = `[${onwardTravelOptionId}, ${returnTravelOptionId}]`
        const { fareId } =
            travelOptionIdsToFareIdsMap?.[key]?.[onwardFareGroup]?.[onwardFareCategory]?.[
                `${onwardBrand}__${returnBrand}`
            ] || {}
        isSpecialRT = fareId ? true : false
        return { isSpecialRT, fareId }
    }
    return { isSpecialRT, fareId: '' }
}

export const selectedFlightsDetails = ({
    selectedFlight,
    metaData,
    subTravelOptions,
    fares,
    showPerAdultPricing
}: {
    selectedFlight: any
    metaData: any
    subTravelOptions: any
    fares: any
    showPerAdultPricing: boolean
}) => {
    if (isEmpty(selectedFlight)) return {}
    const { departureTime, arrivalTime, airlineCode, isMultiAirline, travelOptionId, stops, totalDuration } =
        getFlightInfo(selectedFlight, metaData)
    const fareData = getFaresInfo(selectedFlight, subTravelOptions, fares) || []
    let cheapestFare = fareData.find(fare => fare?.isCheapest)
    if (isEmpty(cheapestFare)) {
        cheapestFare = fareData[0]
    }
    const { fareId } = cheapestFare
    const { displayPrice, strikeOffPrice } = getFarePricingAttributes({
        fare: cheapestFare,
        showPerAdultPricing
    })
    const { totalBaseFare, totalTax, totalPrice } = getFarePricingAttributes({
        fare: cheapestFare,
        showPerAdultPricing: false
    })
    const formatedDepartureTime = getFormattedDate(departureTime, 'HH:mm', 'YYYY-MM-DDTHH:mm:ss')
    const formatedArrivalTime = getFormattedDate(arrivalTime, 'HH:mm', 'YYYY-MM-DDTHH:mm:ss')
    const flightDuration = `${totalDuration.hh ? `${totalDuration.hh}h` : ''} ${
        totalDuration.mm ? `${totalDuration.mm}m` : ''
    }`

    return {
        displayPrice,
        strikeOffPrice,
        formatedDepartureTime,
        formatedArrivalTime,
        airlineCode,
        isMultiAirline,
        travelOptionId,
        fareId,
        stops,
        flightDuration,
        totalBaseFare,
        totalTax,
        totalPrice
    }
}

export const getMasterTuppleProps = ({
    selectedFlights,
    metaData,
    subTravelOptions,
    fares,
    showPerAdultPricing,
    travelOptionIdsToFareIdsMap
}: {
    selectedFlights: any[]
    metaData: any
    subTravelOptions: any
    fares: any
    showPerAdultPricing: boolean
    travelOptionIdsToFareIdsMap: any
}) => {
    const [onwardFlight, returnFlight] = selectedFlights || []
    const { isSpecialRT, fareId: specialRTFareId = '' } = getSpecialRTFareCase(
        onwardFlight,
        returnFlight,
        travelOptionIdsToFareIdsMap
    )

    const onwardLegData = selectedFlightsDetails({
        selectedFlight: onwardFlight,
        metaData,
        subTravelOptions,
        fares,
        showPerAdultPricing
    })
    const {
        displayPrice: onwardFlightPrice = 0,
        formatedDepartureTime: onwardFormatedDepartureTime = '',
        formatedArrivalTime: onwardFormatedArrivalTime = '',
        airlineCode: onwardAirlineCode = '',
        isMultiAirline: isOnwardMultiAirline = false,
        travelOptionId: onwardTravelOptionId = '',
        fareId: onwardFareId = '',
        stops: onwardStops = 0,
        flightDuration: onwardFlightDuration = '',
        totalBaseFare: onwardTotalBaseFare = 0,
        totalTax: onwardTotalTax = 0,
        totalPrice: onwardTotalPrice = 0
    } = onwardLegData || {}

    const returnLegData = selectedFlightsDetails({
        selectedFlight: returnFlight,
        metaData,
        subTravelOptions,
        fares,
        showPerAdultPricing
    })
    const {
        displayPrice: returnFlightPrice = 0,
        formatedDepartureTime: returnFormatedDepartureTime = '',
        formatedArrivalTime: returnFormatedArrivalTime = '',
        airlineCode: returnAirlineCode = '',
        isMultiAirline: isReturnMultiAirline = false,
        travelOptionId: returnTravelOptionId = '',
        fareId: returnFareId = '',
        stops: returnStops = 0,
        flightDuration: returnFlightDuration = '',
        totalBaseFare: returnTotalBaseFare = 0,
        totalTax: returnTotalTax = 0,
        totalPrice: returnTotalPrice = 0
    } = returnLegData || {}

    const totalBaseFare = onwardTotalBaseFare + returnTotalBaseFare
    const totalTax = onwardTotalTax + returnTotalTax
    const totalPrice = onwardTotalPrice + returnTotalPrice
    let totalFare = 0
    let totalStrikeOffPrice = 0
    let actualFlightPrice = onwardFlightPrice + returnFlightPrice
    if (isSpecialRT && specialRTFareId) {
        const selectedfare = fares?.[specialRTFareId]
        const { displayPrice: specialRTPrice } = getFarePricingAttributes({
            fare: selectedfare,
            showPerAdultPricing
        })
        totalFare = specialRTPrice
        totalStrikeOffPrice = actualFlightPrice
    } else {
        totalFare = actualFlightPrice
        totalStrikeOffPrice = 0
    }

    return {
        totalFare,
        totalStrikeOffPrice,
        onwardFlightPrice,
        onwardFormatedDepartureTime,
        onwardFormatedArrivalTime,
        onwardAirlineCode,
        onwardTravelOptionId,
        isOnwardMultiAirline,
        onwardFareId,
        onwardStops,
        onwardFlightDuration,
        returnFlightPrice,
        returnFormatedDepartureTime,
        returnFormatedArrivalTime,
        returnAirlineCode,
        isReturnMultiAirline,
        returnTravelOptionId,
        returnFareId,
        returnStops,
        returnFlightDuration,
        isSpecialRT,
        specialRTFareId,
        totalBaseFare,
        totalTax,
        totalPrice
    }
}

export const findKey = (obj: { [x: string]: any }, targetKey: string, results = []) => {
    for (let key in obj) {
        if (key === targetKey) {
            results.push(obj[key])
        } else if (typeof obj[key] === 'object') {
            findKey(obj[key], targetKey, results)
        }
    }
    return results
}

export const createCopunsPayload = ({
    selectedFlights,
    subTravelOptions,
    fares,
    travelOptionIdsToFareIdsMap,
    searchIntent,
    dataId,
    supercoins
}: {
    selectedFlights: Card[]
    subTravelOptions: SubTravelOptions
    fares: SrpFareObject
    travelOptionIdsToFareIdsMap: { [x: string]: { [x: string]: any } }
    searchIntent: SearchIntentType
    dataId: string
    supercoins: any
}) => {
    const requestedIds = selectedFlights.map((flight: Card) => flight.travelOptionId)
    const key = `[${requestedIds.join(', ')}]`
    const travelOptions = selectedFlights.map((flight: Card) => {
        const { travelOptionId } = flight || {}
        const listOfFares = getFaresInfo(flight, subTravelOptions, fares) || []
        return {
            [travelOptionId]: {
                travelOptionId,
                fareIds: listOfFares.map((fare: any) => {
                    const { fareId, fareGroup = '', fareCategory = '' } = fare || {}
                    return {
                        fareId,
                        fareGroup,
                        fareCategory
                    }
                })
            }
        }
    })
    let fareIdsMap = [{}]
    if (requestedIds.length > 1 && travelOptionIdsToFareIdsMap?.[key]) {
        fareIdsMap[0] = {
            [key]: {
                travelOptionIds: requestedIds,
                fareIds: findKey(travelOptionIdsToFareIdsMap[key], 'fareId').map(fareId => {
                    const { fareGroup = '', fareCategory = '' } = fares?.[fareId] || {}
                    return {
                        fareId,
                        fareGroup,
                        fareCategory
                    }
                })
            }
        }
    }
    const payload = {
        dataId,
        searchIntent,
        couponIntents: {
            requestedIds: [requestedIds],
            travelOptions,
            travelOptionIdsToFareIdsMap: fareIdsMap
        },
        supercoins
    }
    return { key, payload }
}

export const getCouponDisplayAndDiscountValue = ({
    selectedFares,
    showPerAdultPricing,
    isRT,
    couponData,
    cardKey,
    travelOptionIdsToFareIdsMap,
    fares
}) => {
    try {
        let couponDiscountDisplayValue = ''
        let fareKey = ''
        if (!isRT && selectedFares?.[0]) {
            let { couponCallout = '', couponCode } = getFarePricingAttributes({
                fare: selectedFares[0],
                showPerAdultPricing
            })
            return { couponDiscountDisplayValue: couponCallout, discountAmount: 0, couponCode }
        }
        if (selectedFares.length === 2) {
            const { fareId: onwardFareId } = selectedFares[0] || {}
            const { fareId: returnFareId } = selectedFares[1] || {}
            const cardData = couponData?.[cardKey] || {}
            const { isSpecialRT, fareId: specialRTFareId } = getSpecialRTFareCase(
                selectedFares[0],
                selectedFares[1],
                travelOptionIdsToFareIdsMap
            )
            if (isSpecialRT) {
                const { fareId } = fares?.[specialRTFareId]
                fareKey = `[${fareId}]`
            } else {
                fareKey = `[${onwardFareId}, ${returnFareId}]`
            }
            const { couponDetails, perAdultCouponDetails } = cardData?.[fareKey] || {}
            const couponObj = (showPerAdultPricing ? perAdultCouponDetails : couponDetails) || {}
            const { discountAmount, couponCode, message, adultCouponMessage } = couponObj || {}
            if (!isEmpty(couponObj)) {
                couponDiscountDisplayValue = (showPerAdultPricing ? adultCouponMessage : message)
                    .replace('{discountAmount}', formatCurrency(discountAmount))
                    .replace('{couponCode}', couponCode)
            }
            return { couponDiscountDisplayValue, discountAmount, couponCode }
        }
        return { couponDiscountDisplayValue: '', discountAmount: 0, couponCode: '' }
    } catch (e) {
        return { couponDiscountDisplayValue: '', discountAmount: 0, couponCode: '' }
    }
}

const getSelectedCardsIndex = (selectedFares: any[], cards: { [x: string]: any[] }) => {
    let selectedCardsIndex: any = []
    selectedFares.forEach((fare, index) => {
        const card = cards[listOfJourneyKeys[index]]?.find(card => card.travelOptionId === fare.travelOptionId) || {}
        selectedCardsIndex.push(card?.cardId)
    })
    return selectedCardsIndex
}

const getFfAbFeatures = (supercoinVariant) => {
    let abFeatures = []
    if (supercoinVariant === 'default_on') {
        abFeatures.push('SUPER_COIN_TOGGLE_ENABLED')
    }
    return abFeatures;
}

export const getCreateCallPayloadForCfw: (
    selectedFares: FareType[],
    searchData: SearchData,
    defaultCoupon: string,
    isFFFlow: boolean,
    supercoinVariant: string
) => CfwPayloadType = (selectedFares, searchData, defaultCoupon = '', isFFFlow = false, supercoinVariant = '') => {
    const { searchId, searchIntent, cards: resultCardsList, travelOptionIdsToFareIdsMap, fares } = searchData || {}
    const loggedInStatus = isUserSignedIn() ? LOGGED_IN : NON_LOGGED_IN
    const { userId = '' } = getUserAuthValues() || {}
    const sft = new URLSearchParams(window.location.search).get("sft") || "";
    const od = new URLSearchParams(window.location.search).get("isOD") === 'true';
    const isFF = new URLSearchParams(window.location.search).get("isFF") === 'true';

    const sectors = listOfJourneyKeys
        .map((key, index) => {
            const sectorData = searchIntent[key]
            if (isEmpty(sectorData) || !sectorData) {
                return ''
            }
            const { origin = '', destination = '', departDate, cabin, paxCriteria = [] } = sectorData
            return {
                index: index + 1,
                origin: origin,
                destination: destination,
                departDate: departDate,
                cabinType: cabin,
                journeyId: key,
                paxInfos: paxCriteria.map(pax => ({
                    paxType: pax.type,
                    paxCount: pax.count
                }))
            }
        })
        .filter(sector => !isEmpty(sector))
    let abFeatures = isFFFlow ? getFfAbFeatures(supercoinVariant) : []
    let payload = {
        searchId: searchId,
        queryContext: {
            searchIntents: {
                sectors,
                sft
            },
            travelOptionMap: {},
            specialRtData: {}
        },
        responseContext: {
            domain: 'IN',
            currency: 'INR',
            responseVersion: 'V1'
        },
        demandContext: {
            userId,
            loggedInStatus
        },
        metaDataContext: {
            defaultCoupon : isFF ? "" : defaultCoupon,
            abFeatures,
            trackingDetails: {
                selectedCardListId: []
            },
            od: od
        }
    }
    const {
        subTravelOptionId: subTravelOptionIdJ1,
        fareId: fareIdJ1,
        pricing: pricingJ1,
        travelOptionId: travelOptionIdJ1
    } = selectedFares?.[0] || {}
    payload.queryContext.travelOptionMap = {
        J1: {
            subTravelOptions: [
                {
                    subTravelOptionId: subTravelOptionIdJ1,
                    fareId: fareIdJ1,
                    price: pricingJ1?.totalPricing?.totalPrice || ''
                }
            ],
            travelOptionId: travelOptionIdJ1
        }
    }
    if (!isEmpty(selectedFares?.[1])) {
        const { fareId: specialRTFareId, isSpecialRT } = getSpecialRTFareCase(
            selectedFares[0],
            selectedFares[1],
            travelOptionIdsToFareIdsMap
        )
        const {
            subTravelOptionId: subTravelOptionIdJ2,
            fareId: fareIdJ2,
            pricing: pricingJ2,
            travelOptionId: travelOptionIdJ2
        } = selectedFares[1]
        payload.queryContext.travelOptionMap = {
            ...payload.queryContext.travelOptionMap,
            J2: {
                subTravelOptions: [
                    {
                        subTravelOptionId: subTravelOptionIdJ2,
                        fareId: fareIdJ2,
                        price: pricingJ2?.totalPricing?.totalPrice
                    }
                ],
                travelOptionId: travelOptionIdJ2
            }
        }

        if (isSpecialRT) {
            const selectedfare = fares?.[specialRTFareId] || {}
            const { totalPrice } = getFarePricingAttributes({
                fare: selectedfare,
                showPerAdultPricing: false
            })
            const specialRtData = {
                fareId: specialRTFareId,
                price: totalPrice
            }
            payload.queryContext.specialRtData = specialRtData
        }
    }
    payload.metaDataContext.trackingDetails.selectedCardListId = getSelectedCardsIndex(selectedFares, resultCardsList)
    return payload
}

export const getServicesDataCacheKey = (data: any) => {
    const { travelOptions = {}, benefits = {}, baggageAllowances = {} } = data || {}
    let cardIdToBenefitsMap = {
        ...travelOptions
    }
    return {
        cardIdToBenefitsMap,
        benefits,
        baggageAllowances
    }
}

export const getCardServicesData = ({
    servicesData,
    travelOptionId,
    fareId
}: {
    servicesData: ServicesData
    travelOptionId: string
    fareId: string
}) => {
    const { cardIdToBenefitsMap } = servicesData || {}
    const serviceData = cardIdToBenefitsMap[travelOptionId] || {}
    const { subTravelOptions = [] } = serviceData || {}
    let cardServicesData = {}
    
    subTravelOptions.forEach((subTravelOption: any) => {
        const { benefits } = subTravelOption || {}
        if (!isEmpty(benefits?.[fareId])) {            
            cardServicesData = benefits[fareId]
        }
    })
    return cardServicesData
}
export const getBenefitProvided = (fare: any, servicesData: any, baggageAllowances: any) => {
    let benefitsProvided = {
        cancellation: '',
        dateChange: '',
        seat: '',
        meal: '',
        cabinBaggage: '',
        checkInBaggage: ''
    }
    const fareId = fare?.fareId
    const travelOptionId = fare?.travelOptionId
     const cardServicesData: {
        baggageAllowanceId?: any
        benefitIds?: any[]
        penalty?: any[]
    } = getCardServicesData({ servicesData, travelOptionId, fareId })
    const { baggageAllowanceId = '', benefitIds = [], penalty = [] } = cardServicesData || {}
    benefitIds.forEach(id => {
        const benefit = servicesData?.benefits?.[id]?.benefitSummary?.[0]
        if (benefit) benefitsProvided[benefit.benefitType === 'SEAT' ? 'seat' : 'meal'] = benefit.text
    })

    baggageAllowances?.[baggageAllowanceId]?.benefitSummary?.forEach(({ benefitType, text, placeholderMap }) => {
        const formattedText = replacePlaceholders(text, placeholderMap).split('|')[0]
        benefitsProvided[benefitType === 'CHECK_IN_BAGGAGE' ? 'checkInBaggage' : 'cabinBaggage'] = formattedText
    })

    penalty.forEach(({ benefitSummary }) => {
        const { benefitType, text, placeholderMap } = benefitSummary?.[0] || {}
        if (benefitType === 'CANCEL') benefitsProvided.cancellation = replacePlaceholders(text, placeholderMap)
        if (benefitType === 'AMEND') benefitsProvided.dateChange = replacePlaceholders(text, placeholderMap)
    })
    return benefitsProvided
}
export const getFFCookieValue =  () => {
    const ffVar = Statsig?.getExperiment("fare_family_desktop")?.value?.variant
    return `${ffVar || 'null'}`
}