import { SVGProps } from 'react'

export function ChevronLeft({ width = 24, height = 24, className = "", ...rest }: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className={"feather feather-chevron-left " + className}
            {...rest}
        >
            <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
    )
}
