//Used prod for CARAOUSAL_OFFER_SLOT because its name in s3 is prod.
const offerFolderNameOnS3 = 'prod'
export const HOME_API_ENDPOINTS = {
    BBDJSONURL: getBbdJsonURL(),
    TRIP_SUGGESTOR: '/offermgmt/popular_destinations/index.json?trips=',
    CARAOUSAL_OFFER_SLOT: `https://www.cleartrip.com/offermgmt/offerJson/${
        ['staging', 'development'].includes(process.env.NEXT_PUBLIC_CT_ENV || '') ? 'staging' : offerFolderNameOnS3
    }/offerSlot.js?${new Date().getTime()}`,
    HOME_B2C_OFFERS: `/offermgmt/B2Cflights_IN_new.json`,
    AUTO_SUGGEST: `/places/airports/search?string=`,
    HOME_WALLET_INFO: `/partial/account/wallet?_=${new Date().getTime()}`,
    HOME_GET_OTP: `/accounts/external-api/otp`,
    VERIFY_OTP: `/accounts/external-api/otp/verify-action`,
    ACCOUNT_DETAILS:
        process.env.NEXT_PUBLIC_CT_ENV !== 'production' && process.env.NEXT_PUBLIC_CT_ENV !== 'beta'
            ? '/people/v2/fetch/details'
            : '/accounts-api/people/v2/fetch/details',
    FK_ADS: '/monetisation/requestAd',
    DATE_TABS: '/flights/v1/calendar/tab/fares',
    SUPERCOIN_AND_LOYALTY_INFO:'/uhp/getpersonaliseduserinfo'
}
export function getBbdJsonURL() {
    const fileName = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'beta' ? 'qa_bbd_desktop.json' : 'bbd_desktop.json'
    let URL = '/offermgmt/bbdJson/' + fileName
    return URL
}
