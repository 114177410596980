import { PROD_CFW_ID, PROD_NORMAL_ID, QA_CFW_ID, QA_NORMAL_ID } from '@/constants/flights'
import { isUserSignedIn } from '../general/user'

export const isCfwFlow = ({ isLoginMandatory = false }) => {
    const isUserLoggedIn = isLoginMandatory ? true : true
    return isUserLoggedIn
}
export const getPartnerIdCfw = () =>{
    const isProd = process.env.NEXT_PUBLIC_CT_ENV === 'production' || process.env.NEXT_PUBLIC_CT_ENV === 'beta';
    
       return isProd ? PROD_CFW_ID : QA_CFW_ID
    return 100000
}
export const getPartnerId = (isCfw) => {
    if (isCfw) {
        return getPartnerIdCfw()
    }
    else {
        const isProd = process.env.NEXT_PUBLIC_CT_ENV === 'production' || process.env.NEXT_PUBLIC_CT_ENV === 'beta';
       return isProd ? PROD_NORMAL_ID : QA_NORMAL_ID
    }

}
